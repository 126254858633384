var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OrganizationDropdown", { on: { change: _vm.onChange } }),
      _c("div", { staticClass: "list" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.onReachBottom,
                expression: "onReachBottom"
              }
            ],
            attrs: {
              "infinite-scroll-disabled": "loading",
              "infinite-scroll-distance": "10"
            }
          },
          _vm._l(_vm.list, function(item, index) {
            return _c("div", { key: index, staticClass: "listCt" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  item.type == 2
                    ? [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/enterprise/water.png")
                          }
                        }),
                        _c("span", [_vm._v("水费账单")])
                      ]
                    : _vm._e(),
                  item.type == 3
                    ? [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/enterprise/electric.png")
                          }
                        }),
                        _c("span", [_vm._v("电费账单")])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c("div", { staticClass: "project" }, [
                _c("p", { staticClass: "project-name" }, [_vm._v("机构名称")]),
                _c("p", { staticClass: "project-main" }, [
                  _vm._v(_vm._s(item.orgName))
                ])
              ]),
              _c("div", { staticClass: "project" }, [
                _c("p", { staticClass: "project-name" }, [_vm._v("账单周期")]),
                _c("p", { staticClass: "project-main" }, [
                  _vm._v(_vm._s(item.startTime) + "~" + _vm._s(item.endTime))
                ])
              ]),
              _c("div", { staticClass: "project" }, [
                _c("p", { staticClass: "project-name" }, [_vm._v("账单金额")]),
                _c("p", { staticClass: "project-main" }, [
                  _vm._v("￥" + _vm._s(item.money))
                ])
              ]),
              _c("div", { staticClass: "project" }, [
                _c("p", { staticClass: "project-name" }, [_vm._v("创建日期")]),
                _c("p", { staticClass: "project-main" }, [
                  _vm._v(_vm._s(item.createTime))
                ])
              ]),
              _c("div", { staticClass: "project" }, [
                _c("p", { staticClass: "project-name" }, [_vm._v("缴费状态")]),
                _c("p", { staticClass: "project-main" }, [
                  _vm._v(_vm._s(item.payStatus_dictText))
                ])
              ])
            ])
          }),
          0
        ),
        !_vm.loading
          ? _c("div", { staticClass: "nodata" }, [_vm._v("已无更多数据")])
          : _vm._e(),
        !_vm.isEnd && _vm.loading
          ? _c(
              "div",
              { staticClass: "loading" },
              [
                _c("mt-spinner", { attrs: { color: "#178CF9", type: "snake" } })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }